<template>
  <div class="mail-form">
    <div class="dashboard-margin">
      <div class="m-0 container-form-preview">
        <div class="custom-card">
          <div class="row align-items-end">
            <div class="col-12 col-lg-6">
              <h3 v-if="id === null">{{ $t("createMail") }}</h3>
              <h3 v-else>{{ $t("editMail") }}</h3>
              <base-button
                class="p-0 back-button d-flex justify-content-between px-2"
                @click="backToList"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="/img/icons/stevie/back-shape.svg"
                    class="rotateArrow mr--2"
                    alt="Back icon"
                  />
                  <img
                    src="/img/icons/stevie/back-shape.svg"
                    class="rotateArrow mr-2"
                    alt="Back icon"
                  />
                  {{ $t("allItems", [$tc("mail", 2)]) }}
                </div>
              </base-button>
            </div>
          </div>
          <div class="pt-3 px-0">
            <Form
              ref="mailFormRef"
              @submit="manageMail"
              :initialValues="mail"
              @change="uploadForm($event, mail);hasUnsavedChanges=true"
              :validation-schema="schema"
              v-slot="{ errors }"
            >
              <section class="segment highlight-segment">
                <h3 class="subtitle-form">{{$t("formSegment.general")}}</h3>
                <div class="row mb-4">
                  <div class="col-12 col-lg-8 d-flex flex-column">
                    <label for="name" class="label d-block">{{$t('mailForm.placeholder.name')}}</label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      :label="$t('mailForm.placeholder.name')"
                      :placeholder="$t('mailForm.placeholder.name')"
                      class="input-stevie"
                    ></Field>
                    <ErrorMessage name="name" class="text-danger text-required-error" />
                  </div>
                  <div class="col-12 col-lg-4 d-flex flex-column">
                    <label for="status">{{ $t("status") }}</label>
                    <el-select
                      :disabled="isDisabledEdit"
                      v-model="mail.status"
                      :placeholder="$t('select.status')"
                      @change="changeMailStatus"
                    >
                        <el-option
                          v-for="status in optionsForm.status"
                          :key="status.id"
                          :value="status.id"
                          :label="$t(`statusList.${status.label}`)"
                          :disabled="id === null"
                        >
                        </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 d-flex flex-column">
                    <label for="subject" class="label d-block">{{$t("subject")}}</label>
                    <Field
                      id="subject"
                      name="subject"
                      type="text"
                      :label="$t('subject')"
                      :placeholder="$t('subject')"
                      class="input-stevie"
                    ></Field>
                    <ErrorMessage name="subject" class="text-danger text-required-error" />
                  </div>
                  <div class="col-12 col-lg-2 d-flex flex-column">
                    <label class="">{{ $t("backgroundColor") }}</label>
                    <div class="d-block">
                      <el-color-picker
                        :disabled="isDisabledEdit"
                        v-model="mail.background"
                        color-format="hex"
                        @change="mail.background = $event ? $event : '#ffffff';hasUnsavedChanges=true"
                      ></el-color-picker>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 d-flex flex-column">
                    <label class="">{{ $t("categories") }}</label>
                    <Field name="categories" v-slot="{ field }">
                      <el-select
                        v-model="mail.categories"
                        multiple
                        collapse-tags
                        collapse-tags-tooltip
                        :placeholder="$t('select.categories')"
                        @change="hasUnsavedChanges=true"
                      >
                        <el-option
                          v-for="category in categories"
                          :key="category.id"
                          :label="$t(`templateCategories.${category.id}`)"
                          :value="category.id"
                        />
                      </el-select>
                    </Field>
                    <ErrorMessage name="categories" class="text-danger text-required-error" />
                  </div>
                </div>
              </section>
              <section class="row segment">
                <h3 class="col-12 subtitle-form pl-0">{{$t("formSegment.mailHeader")}}</h3>
                <div class="col-12 row justify-space-between mx-0 px-0 mb-3">
                  <div class="col-12 col-lg-6 px-0 d-flex flex-column">
                    <label for="title_text" class="label d-block">{{$t("mailForm.header")}}</label>
                    <Field
                      id="title_text"
                      name="title_text"
                      type="text"
                      :label="$t('mailForm.header')"
                      :placeholder="$t('mailForm.placeholder.header')"
                      class="input-stevie"
                      v-slot="{ field }"
                    >
                      <textarea
                        v-model="mail.title_text"
                        id="title_text"
                        class="input-stevie p-2 textarea-header"
                        :placeholder="$t('mailForm.placeholder.header')"
                      />
                    </Field>
                  </div>
                  <div class="col-12 col-lg-6 pr-lg-0 px-0 pl-lg-3 d-flex flex-wrap justify-content-lg-around option-text">
                    <div class="max-input mr-2 d-flex flex-column">
                      <label class="">{{ $t("font") }}</label>
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="mail.title_font"
                          :placeholder="$t('select.font')"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="font in googleFonts"
                              :key="font.family"
                              :value="font.family"
                              :label="font.family"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="mr-2 input-font-size d-flex flex-column">
                      <label class="">{{ $t("fontSize") }}</label>
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="mail.title_font_size"
                          :placeholder="$t('select.font')"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="size in optionsFontSize"
                              :key="size.value"
                              :value="size.value"
                              :label="size.value"
                            >
                            </el-option>
                        </el-select>
                    </div>

                    <div class="mr-2 d-flex flex-column">
                      <label class="">{{ $t("mailForm.text") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model="mail.title_color"
                          color-format="hex"
                          @change="mail.title_color = $event ? $event : '#000000';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>

                    <div class="option d-flex flex-column">
                      <label class="">{{ $t("align") }}</label>
                      <icon-button-radio-group
                        :isDisabledEdit="isDisabledEdit"
                        :options="alignButtons"
                        button-classes="text-dark input-stevie py-1 px-3"
                        v-model:value="mail.title_align"
                        @change:modelValue="mail.title_align = $event;hasUnsavedChanges=true"
                        name="title_align"
                      >
                      </icon-button-radio-group>
                    </div>

                  </div>
                </div>

                <div class="col-12 row justify-space-between mx-0 px-0 mb-3">
                  <div class="col-12 col-lg-6 px-0">
                    <label for="subtitle_text" class="label d-block">{{$t("mailForm.subheader")}}</label>
                    <Field
                      id="subtitle_text"
                      name="subtitle_text"
                      type="text"
                      :label="$t('mailForm.subheader')"
                      :placeholder="$t('mailForm.placeholder.subheader')"
                      class="input-stevie"
                      v-slot="{ field }"
                    >
                      <textarea
                        v-model="mail.subtitle_text"
                        id="subtitle_text"
                        class="input-stevie p-2 textarea-header"
                        :placeholder="$t('mailForm.placeholder.subheader')"
                      />
                    </Field>
                  </div>

                  <div class="col-12 col-lg-6 px-0 pl-lg-3 pr-lg-0 d-flex flex-wrap justify-content-lg-around option-text">
                    <div class="max-input mr-2 d-flex flex-column">
                      <label class="">{{ $t("font") }}</label>
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="mail.subtitle_font"
                          :placeholder="$t('select.font')"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="font in googleFonts"
                              :key="font.family"
                              :value="font.family"
                              :label="font.family"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="input-font-size mr-2 d-flex flex-column">
                      <label class="">{{ $t("fontSize") }}</label>
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="mail.subtitle_font_size"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="size in optionsFontSize"
                              :key="size.value"
                              :value="size.value"
                              :label="size.value"
                            >
                            </el-option>
                        </el-select>
                    </div>

                    <div class="mr-2 d-flex flex-column">
                      <label class="">{{ $t("mailForm.text") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model="mail.subtitle_color"
                          color-format="hex"
                          @change="mail.subtitle_color = $event ? $event : '#000000';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>

                    <div class="option d-flex flex-column">
                      <label class="">{{ $t("align") }}</label>
                      <icon-button-radio-group
                        :isDisabledEdit="isDisabledEdit"
                        :options="alignButtons"
                        button-classes="text-dark input-stevie py-1 px-3"
                        v-model:value="mail.subtitle_align"
                        @change:modelValue="mail.subtitle_align = $event;hasUnsavedChanges=true"
                        name="subtitle_align"
                      >
                      </icon-button-radio-group>
                    </div>

                  </div>
                </div>
                <div class="col-12 col-lg-6 px-0 mb-4">
                  <label class="">{{ $t("image") }}</label>
                  <Field name="image_header_file_rule" v-model="image_header_file_rule" v-slot="{ field }">
                    <el-upload
                      v-bind="field"
                      accept=".jpeg,.jpg,.png,.gif"
                      class="avatar-uploader w-100 header-image"
                      action=""
                      drag
                      :auto-upload="false"
                      :show-file-list="false"
                      :on-change="uploadFile => getImage(uploadFile, 'image_header_file', mail)"
                      :before-upload="validateImage"
                      :disabled="isDisabledEdit"
                    >
                      <div
                        v-if="mail.image_header_file === null"
                        class="el-upload__text texto"
                      >
                        {{ $t("dropImageHere") }}
                      </div>
                      <div v-else>
                        <img
                          :src="getImageUrl(mail.image_header_file)"
                          class="avatar url"
                        />
                      </div>
                    </el-upload>
                  </Field>
                  <ErrorMessage name="image_header_file_rule" class="text-danger text-required-error error-image-top" />
                </div>
                <div class="col-12 col-lg-6 px-0 pl-lg-3 mb-4">
                  <label class="mt-2">{{ $t("align") }}</label>
                  <Field name="image_header_align" v-slot="{ field }">
                    <icon-button-radio-group
                      :isDisabledEdit="isDisabledEdit"
                      :options="alignButtonsPicture"
                      button-classes="text-dark input-stevie py-1"
                      :isPicture="true"
                      v-model:value="mail.image_header_align"
                      @change:modelValue="mail.image_header_align = $event;hasUnsavedChanges=true"
                      name="image_header_align"
                    >
                    </icon-button-radio-group>
                  </Field>
                  <ErrorMessage name="image_header_align" class="text-danger text-required-error" />
                </div>
              </section>
              <template v-for="(block, index) in mail.blocks" :key="index">
                <div class="row segment highlight-segment mt-1">
                  <div class="col-12 px-0 d-flex justify-content-between">
                    <h3 class="subtitle-form">{{$t("formSegment.contentBlock", [index+1])}}</h3>
                    <base-button
                      v-if="mail.blocks.length > 1"
                      @click="manageConfirmModal('deleteBlock', index)"
                      class="button-icon d-flex justify-content-center align-items-center"
                      :disabled="isLoading"
                      size="sm"
                      outline
                    >
                      <div v-if="!isLoading">
                        <em class="fas fa-trash-alt fa-lg text-danger"></em>
                      </div>
                      <clip-loader
                        v-else
                        color="#ffffff"
                        size="20px"
                        class="pt-2"
                      ></clip-loader>
                    </base-button>
                  </div>
                  <div class="col-12 col-lg-6 px-0 mb-lg-4">
                    <label>{{ $t("image") }}</label>
                    <el-upload
                      accept=".jpeg,.jpg,.png,.gif"
                      class="avatar-uploader w-100 mb-2 block-image"
                      action=""
                      drag
                      :auto-upload="false"
                      :show-file-list="false"
                      :on-change="
                        uploadFile =>
                          getImage(
                            uploadFile,
                            `image_url`,
                            block
                          )
                      "
                      :before-upload="validateImage"
                      :disabled="isDisabledEdit"
                    >
                      <div
                        v-if="
                          mail.blocks[index][
                            `image_url`
                          ] === null
                        "
                        class="el-upload__text texto"
                      >
                        {{ $t("dropImageHere") }}
                      </div>
                      <div v-else>
                          <span class="el-upload-list__item-actions">
                            <span
                              class="el-upload-list__item-delete"
                              @click.stop="handleRemove(`image_url`, block)"
                            >
                              <em class="far fa-trash-alt fa-lg"></em>
                            </span>
                          </span>
                        <img
                          :src="getImageUrl(mail.blocks[index][`image_url`])"
                          class="avatar url"
                        />
                      </div>
                    </el-upload>
                  </div>

                  <div class="col-12 col-lg-6 px-0 pl-lg-3 mb-4">
                    <label class="">{{ $t("align") }}</label>
                    <icon-button-radio-group
                      :isDisabledEdit="isDisabledEdit"
                      :options="alignButtonsPicture"
                      button-classes="text-dark input-stevie py-1"
                      :isPicture="true"
                      v-model:value="mail.blocks[index].image_align"
                      @change:modelValue="mail.blocks[index].image_align = $event;hasUnsavedChanges=true"
                      :name="`image_align_${index}`"
                    >
                    </icon-button-radio-group>
                  </div>

                  <div class="col-12 col-lg-6 px-0 mb-lg-4">
                    <label :for="`blocks[${index}]text_text`" class="label d-block">{{ $t("text") }}</label>
                    <Field
                      :disabled="isDisabledEdit"
                      :id="`blocks[${index}]text_text`"
                      :name="`blocks[${index}]text_text`"
                      type="text"
                      :label="$t('text')"
                      :placeholder="$t('mailForm.placeholder.blockText')"
                      class="input-stevie"
                      v-slot="{ field }"
                    >
                      <textarea
                        v-model="mail.blocks[index].text_text"
                        id="subtitle_text"
                        class="input-stevie p-2 textarea-header"
                        maxLength="500"
                        :placeholder="$t('mailForm.placeholder.blockText')"
                      />
                    </Field>
                  </div>

                  <div class="col-12 col-lg-6 px-0 pl-lg-3 pr-lg-0 mb-4 d-flex flex-wrap justify-content-lg-around option-text">
                    <div class="max-input mr-2 d-flex flex-column">
                      <label class="">{{ $t("font") }}</label>
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="mail.blocks[index].text_font"
                          :placeholder="$t('select.font')"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="font in googleFonts"
                              :key="font.family"
                              :value="font.family"
                              :label="font.family"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="input-font-size mr-2 d-flex flex-column">
                      <label class="">{{ $t("fontSize") }}</label>
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="mail.blocks[index].text_font_size"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="size in optionsFontSize"
                              :key="size.value"
                              :value="size.value"
                              :label="size.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="mr-2 d-flex flex-column">
                      <label class="">{{ $t("mailForm.text") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model="mail.blocks[index].text_color"
                          color-format="hex"
                          @change="mail.blocks[index].text_color = $event ? $event : '#000000';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>
                    <div class="option d-flex flex-column">

                      <label class="">{{ $t("align") }}</label>
                      <icon-button-radio-group
                        :isDisabledEdit="isDisabledEdit"
                        :options="alignButtons"
                        button-classes="text-dark input-stevie py-1"
                        v-model:value="mail.blocks[index].text_align"
                        @change:modelValue="mail.blocks[index].text_align = $event;hasUnsavedChanges=true"
                        :name="`text_align_${index}`"
                      >
                      </icon-button-radio-group>
                    </div>

                  </div>
                  <div class="col-12 col-lg-6 px-0 d-flex flex-column">
                    <label :for="`blocks[${index}]link_text`" class="label d-block">{{ $t("mailForm.btnText") }}</label>
                    <Field
                      :disabled="isDisabledEdit"
                      :id="`blocks[${index}]link_text`"
                      :name="`blocks[${index}]link_text`"
                      type="text"
                      :label="$t('mailForm.btnText')"
                      :placeholder="$t('mailForm.placeholder.btnText')"
                      class="input-stevie"
                    ></Field>
                  </div>
                  <div class="col-12 col-lg-6 px-0 pl-lg-3 mb-lg-4 d-flex flex-column">
                    <label :for="`blocks[${index}]link_url`" class="label d-block">{{ $t("mailForm.link") }}</label>
                    <Field
                      :disabled="isDisabledEdit"
                      :id="`blocks[${index}]link_url`"
                      :name="`blocks[${index}]link_url`"
                      type="text"
                      :label="$t('mailForm.link')"
                      :placeholder="$t('mailForm.placeholder.link')"
                      class="input-stevie"
                      @blur="addHttpsToUrl(index, block.link_url)"
                      rules="required|invalidUrl"
                    ></Field>
                    <ErrorMessage :name="`blocks[${index}]link_url`" class="text-danger text-required-error" />
                  </div>
                  <div class="col-12 px-0 mb-4 d-flex option-text">
                    <div class="max-input mr-2">
                      <label class="">{{ $t("font") }}</label>
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="mail.blocks[index].link_font"
                          :placeholder="$t('select.font')"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="font in googleFonts"
                              :key="font.family"
                              :value="font.family"
                              :label="font.family"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="mr-2 input-font-size">
                      <label class="">{{ $t("fontSize") }}</label>
                        <el-select
                          :disabled="isDisabledEdit"
                          v-model="mail.blocks[index].link_font_size"
                          @change="hasUnsavedChanges=true"
                        >
                            <el-option
                              v-for="size in optionsFontSize"
                              :key="size.value"
                              :value="size.value"
                              :label="size.value"
                            >
                            </el-option>
                        </el-select>
                    </div>

                    <div class="mr-2">
                      <label class="">{{ $t("mailForm.text") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model="
                            mail.blocks[index].link_color"
                          color-format="hex"
                          @change="mail.blocks[index].link_color= $event ? $event : '#ffffff';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>

                    <div class="mr-2">
                      <label class="">{{ $t("mailForm.btn") }}</label>
                      <div class="d-block">
                        <el-color-picker
                          :disabled="isDisabledEdit"
                          v-model=" mail.blocks[index].link_background"
                          color-format="hex"
                          @change=" mail.blocks[index].link_background = $event ? $event : '#86d3f7';hasUnsavedChanges=true"
                        ></el-color-picker>
                      </div>
                    </div>
                    <div class="option">
                      <label class="">{{ $t("align") }}</label>
                      <icon-button-radio-group
                        :isDisabledEdit="isDisabledEdit"
                        :options="alignButtons"
                        button-classes="text-dark input-stevie py-1"
                        v-model:value="mail.blocks[index].link_align"
                        @change:modelValue="mail.blocks[index].link_align = $event;hasUnsavedChanges=true"
                        :name="`link_align_${index}`"
                      >
                      </icon-button-radio-group>
                    </div>
                  </div>
                </div>
              </template>
              <div class="row mb-2">
                <div class="col-12 d-flex justify-content-center">
                  <base-button
                    borderRadiusLeft
                    borderRadiusRight
                    @click="addBlock()"
                    class="d-flex justify-content-center align-items-center btn-light-blue"
                    :disabled="isLoading"
                    size="sm"
                    outline
                  >
                    <div class="w-100 d-flex justify-content-around align-items-center ">
                      <img
                        class="mr-1 add-icon-size"
                        src="/img/icons/stevie/add-shape-color-blue-light.svg"
                        alt="Add icon"
                      />
                      <span> {{ $t("addBlock") }}</span>
                    </div>
                  </base-button>
                </div>
              </div>
              <section class="row segment">
                <h3 class="col-12 px-0 subtitle-form">{{ $t("footer") }}</h3>
                <div class="col-12 col-lg-6 pl-0 mb-4">
                  <label class="">{{ $t("mailForm.text") }}</label>
                  <vue-editor
                    v-model="mail.footer_text"
                    name="footer_text"
                    :editor-toolbar="basicToolbarHtmlEditor"
                    :disabled="isDisabledEdit"
                    @text-change="detectChangesInFooterText"
                  />
                </div>
                <div class="col-12 col-lg-6 pr-lg-0 px-0 pl-lg-3 d-flex flex-wrap justify-content-lg-around h-100 option-text">
                  <div class="max-input mr-2">
                    <label class="">{{ $t("font") }}</label>
                      <el-select
                        :disabled="isDisabledEdit"
                        v-model="mail.footer_font"
                        :placeholder="$t('select.font')"
                        @change="hasUnsavedChanges=true"
                      >
                          <el-option
                            v-for="font in googleFonts"
                            :key="font.family"
                            :value="font.family"
                            :label="font.family"
                          >
                          </el-option>
                      </el-select>
                  </div>
                  <div class="mr-2 input-font-size">
                    <label class="">{{ $t("size") }}</label>
                      <el-select
                        :disabled="isDisabledEdit"
                        v-model="mail.footer_font_size"
                        @change="hasUnsavedChanges=true"
                      >
                          <el-option
                            v-for="size in optionsFontSize"
                            :key="size.value"
                            :value="size.value"
                            :label="size.value"
                          >
                          </el-option>
                      </el-select>
                  </div>

                  <div class="mr-2">
                    <label class="">{{ $t("color") }}</label>
                    <div class="d-block">
                      <el-color-picker
                        :disabled="isDisabledEdit"
                        v-model="mail.footer_color"
                        color-format="hex"
                        @change="mail.footer_color = $event ? $event : '#e2e6ea';hasUnsavedChanges=true"
                      ></el-color-picker>
                    </div>
                  </div>

                  <div>
                    <label class="">{{ $t("backgroundColor") }}</label>
                    <div class="d-block">
                      <el-color-picker
                        :disabled="isDisabledEdit"
                        v-model="mail.footer_background"
                        color-format="hex"
                        @change="mail.footer_background = $event ? $event : '#666666';hasUnsavedChanges=true"
                      ></el-color-picker>
                    </div>
                  </div>

                  <div class="option">
                    <label class="">{{ $t("align") }}</label>
                    <icon-button-radio-group
                      :isDisabledEdit="isDisabledEdit"
                      :options="alignButtons"
                      button-classes="text-dark input-stevie py-1 px-3"
                      v-model:value="mail.footer_align"
                      @change:modelValue="mail.footer_align = $event;hasUnsavedChanges=true"
                      name="footer_align"
                    >
                    </icon-button-radio-group>
                  </div>

                </div>
              </section>
              <section class="segment highlight-segment footer row d-flex justify-content-center">
                <base-button
                  native-type="submit"
                  @click="manageMail"
                  borderRadiusLeft
                  borderRadiusRight
                  class="p-0 start-campaign-button"
                  :disabled="isLoading || isDisabledEdit"
                >
                  <span v-if="!isLoading"> {{ $t("saveMail") }}</span>
                  <clip-loader
                    v-else
                    color="#ffffff"
                    size="20px"
                    class="pt-2"
                  ></clip-loader>
                </base-button>
              </section>
            </Form>
          </div>
        </div>
        <div class="preview-mail">
          <div>
            <TemplatePreview :template="mail" ></TemplatePreview>
          </div>
        </div>
      </div>
    </div>

    <ConfirmModal
      v-if="showConfirmModal"
      :showModal="showConfirmModal"
      :modalInfo="modalConfirm"
      :isLoading="isLoading"
      @confirm="modalConfirm.confirmAction(true)"
      @cancel="modalConfirm.cancelAction(false)"
      @closeModal="showConfirmModal = false"
    ></ConfirmModal>

    <ModalUnsavedChanges
      :hasUnsavedChanges="hasUnsavedChanges && isLeaveView"
      @close="closeUnsavedChanges()"
      @saveChanges="saveChanges()"
      :isLoading="isLoading"
    ></ModalUnsavedChanges>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n/index";
import { computed, onMounted, onUnmounted, ref } from "vue";

import ConfirmModal from "@/views/Components/ConfirmModal";
import TemplatePreview from "@/views/Components/TemplatePreview.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import useForms from "@/composables/useForms";
import { BlockFormClass} from "@/util/Models/TemplateClass.js";
import { MailFormClass, MailClass} from "@/util/Models/MailClass.js";
import { ElColorPicker, ElUpload, ElSelect, ElOption } from 'element-plus';
import useMails from "@/composables/useMails";
import useSubscription from "@/composables/useSubscription";
import { VueEditor } from "vue3-editor";
import { basicToolbarHtmlEditor, optionsAlignButtons, optionsAlignButtonsPicture, optionsFontSize } from '@/util/Settings';
import {getImageUrl} from '@/util/helperFunctions.js'
import IconButtonRadioGroup from "@/components/Stevie/IconButtonRadioGroup";
import { addHttpOrHttps } from "@/composables/useFormatUrl";
import { allSubscriptions } from '@/util/Settings.js';
import ModalUnsavedChanges from "@/views/Components/ModalUnsavedChanges.vue";

export default {
  name: "MailForm",
  props: ["id", "isClone"],
  components: {
    ClipLoader,
    Form,
    Field,
    ErrorMessage,
    ConfirmModal,
    ElColorPicker,
    ElUpload,
    VueEditor,
    TemplatePreview,
    IconButtonRadioGroup,
    ElSelect,
    ElOption,
    ModalUnsavedChanges
  },
  setup(props) {
    const mail = ref(new MailFormClass({}));
    const deleteItemId = ref(null)
    const previewMail = ref(null)
    const initStatus = ref(0)
    const { subscriptionPermissions, changeSuscriptionModal } = useSubscription()
    const allowPublishMails = subscriptionPermissions.value.allowMails
    const {getMail, updateMail, storeMail, archiveMail, getCategories, categories} = useMails()
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { t } = useI18n();
    const { markAsInvalid, isValidForm, isValidLink, uploadForm, createFormData, removeHtmlTags } = useForms();
    const id = ref(null);
    const isLoading = ref(false);
    const validationsForms = ref({});
    const modalConfirm = ref({
      title: null,
      subtitle: null,
      textCancel: null,
      textConfirm: null,
      confirmAction: null,
      cancelAction: null,
      allowClose: false,
    });
    const showConfirmModal = ref(false);
    const optionsForm = ref({
      status: [
        { id: 0, label: "pending" },
        { id: 1, label: "ready" },
        { id: 2, label: "archived" },
      ],
      alignsText: ["left", "center", "right"],
    });
    const alignButtons = ref(optionsAlignButtons)
    const alignButtonsPicture = ref(optionsAlignButtonsPicture)
    const googleFonts = ref([
      {family: "Helvetica"},
      {family: "Verdana"},
      {family: "Sans Serif"},
      {family: "Georgia"},
      {family: "Times New Roman"},
      {family: "Arial"},
    ])
    const mailFormRef = ref(null);
    const hasUnsavedChanges = ref(false);
    const isLeaveView = ref(false);
    const image_header_file_rule = ref(null);
    const schema = {
      name: "required",
      subject: "required",
      categories: "required",
      image_header_align: "required",
      image_header_file_rule: "required"
    }


    const isDisabledEdit = computed({
      get: () => {
        return id.value !== null && mail.value.status == 2 || isLoading.value;
      },
    });
    const manageMail = async () => {

      let requiredFields = [
        "name",
        "subject",
        "status",
        "background",
        "image_header_file",
        "image_header_align",
        "categories"
      ];

      let areLinksValid = true
      mail.value.blocks.forEach(block =>{
        block.link_url = addHttpOrHttps(block.link_url);

        if(block.link_url && block.link_url !== '' && !isValidLink(block.link_url)){
          areLinksValid = false;
        }
      })

      if (!isValidForm(mail.value, requiredFields, areLinksValid)) return;
      const mailForm = new MailClass(mail.value);

      const formData = createFormData(mailForm);
      formData.delete('blocks_images');
      mailForm.blocks_images.forEach((item, index)=>{
        formData.append(`blocks_images[${index}]`, item)
      })

      let response;
      isLoading.value = true;
      if (id.value) {
        response = await updateMail(formData, id.value);
      } else {
        response = await storeMail(formData);
      }
      if(response && response.status === 200){
        if(!id.value){
          hasUnsavedChanges.value = false;
          backToList();
        }else{
          mail.value = new MailFormClass(response.data)
        }
      }
      isLoading.value = false;
      hasUnsavedChanges.value = false;
      return response;
    };

    const detectChangesInFooterText = (type, event) => {
      const inputChanges = event.ops[0].insert === '\n'? null : event.ops[0].insert;
      const footerFormatted = removeHtmlTags(mail.value.footer_text);

      if( inputChanges && inputChanges !== footerFormatted ) {
        hasUnsavedChanges.value = true;
      }
    }

    const backToList = () => {
      if(hasUnsavedChanges.value) {
        isLeaveView.value = true;
        return;
      }
      const savedFilters = JSON.parse(localStorage.getItem('mailsFilters'))

      router.push({ name: "MailsList",  query: savedFilters});
    };
    const manageConfirmModal = (action, id) => {
      deleteItemId.value = id;
      let msg;
      let confirmAction;
      if(action === 'deleteBlock'){
        msg = 'deleteItem'
        confirmAction = (()=>{removeBlock(id); hasUnsavedChanges.value = true; });
      }
      if(action === 'archiveMail'){
        msg = 'archiveMail'

        confirmAction = initArchiveMail;
      }
      modalConfirm.value = {
        title: `${msg}.title` ,
        subtitle: `${msg}.subtitle` ,
        textCancel: `no` ,
        textConfirm: `yes` ,
        allowClose: false,
        confirmAction: confirmAction,
        cancelAction: closeModal,
      };
      setTimeout(() => {
        showConfirmModal.value = true;
      }, 500);
    };
    const closeModal = () => {
      showConfirmModal.value = false
      deleteItemId.value = null;
      mail.value.status = initStatus.value

    }
    const initArchiveMail = async()=>{
      isLoading.value = true;
      const response = await archiveMail(id.value)
      if(response && response.status === 200){
        initStatus.value = 2;
        backToList()
      }else{
        closeModal()
      }
      isLoading.value = false;
    }
    const validateImage = (event, field) => {
      //add validation in function of field
      return true;
    };
    const getImage = (file, field, object) => {
      hasUnsavedChanges.value = true;
      object[field] = file.raw;
    };

    const addBlock = () => {
      hasUnsavedChanges.value = true;
      mail.value.blocks.push(new BlockFormClass({}));
    };
    const removeBlock = (index) => {
      closeModal()
      if (mail.value.blocks.length > 1) {
        mail.value.blocks.splice(index, 1);
      }
    };
    const changeMailStatus = (value) => {
      hasUnsavedChanges.value = true;
      if(value === 2 || !allowPublishMails){
        const subscriptionRequired = allSubscriptions.find(subscription => subscription.permissions.allowMails)
        changeSuscriptionModal(true, subscriptionRequired.name)
        return
      }
      let newStatus= value
      if(newStatus == 2 && id.value === null)return

      if(newStatus == 2){
        manageConfirmModal('archiveMail', null)
      }else{
        mail.value.status = newStatus;
        initStatus.value = newStatus;
      }
    }
    const handleRemove = (field, form)=>{
      hasUnsavedChanges.value = true;
      form[field]=null;
    }

    const listCategories = async () => {
      await getCategories()
    }

    const saveChanges = async() => {
      isLoading.value = true;
      let response = await manageMail();
      isLoading.value = false;
      hasUnsavedChanges.value= false;
      isLeaveView.value = false;
      if(response && response.status === 200){
        backToList()
      }else{
        hasUnsavedChanges.value = false;
      }
    };

    const closeUnsavedChanges = ()=>{
      hasUnsavedChanges.value=false;
      isLeaveView.value= false;
      backToList()
    }

    const addHttpsToUrl = (index, url) => {
      mail.value.blocks[index].link_url = addHttpOrHttps(url)
      mailFormRef.value.resetForm();
    }

    onMounted(async () => {
      if (route.params.id) {
        id.value = route.params.id;
        isLoading.value = true;
        const response = await getMail(route.params.id);
        if(response && response.status === 200){
          mail.value = new MailFormClass(response.data)
          image_header_file_rule.value = mail.value.image_header_file;
          initStatus.value = response.data.status;
        }
        isLoading.value = false;
      }
      await listCategories()
    });
    onUnmounted(()=>{
      localStorage.removeItem('mailsFilters')
    })

    return {
      mail,
      id,
      isLoading,
      previewMail,
      validationsForms,
      isDisabledEdit,
      optionsForm,
      showConfirmModal,
      modalConfirm,
      markAsInvalid,
      basicToolbarHtmlEditor,
      manageMail,
      uploadForm,
      backToList,
      manageConfirmModal,
      changeMailStatus,
      validateImage,
      isValidLink,
      getImage,
      addBlock,
      removeBlock,
      getImageUrl,
      handleRemove,
      alignButtons,
      alignButtonsPicture,
      googleFonts,
      optionsFontSize,
      allowPublishMails,
      mailFormRef,
      categories,
      closeUnsavedChanges,
      isLeaveView,
      saveChanges,
      hasUnsavedChanges,
      detectChangesInFooterText,
      schema,
      addHttpsToUrl,
      image_header_file_rule
    };
  },
};
</script>

<style scoped lang="scss">
.container-form-preview{
  display: flex;
  .preview-mail{
    min-width: 300px;
    width:300px;
    padding-left: 30px;
  }
}
.header-image{
    height: 159px!important;
  }
  .block-image{
    height: 91px!important;
  }
:deep().el-upload {
  width: 100%;
  height: 100%!important;
  .el-upload-list__item-actions{
    background-color: rgba(0,0,0, 0.5);
    opacity: 0;
    position:absolute;
    width: 100%;
    height: 100%;
    &:hover{
      opacity: 1;
      display: block;
    }
    .el-upload-list__item-delete{
      display: block;
      padding-top: 10px;
      color: #ffffff;
    }
  }
}
:deep().el-upload-dragger {
  width: 100%;
  margin: 0;
  border-radius: 5px;
  border: 2px dashed #86d3f7;
  background: transparent;
  height: 100%;

  .el-upload__text {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #012454;
    font-weight: 600;

  }
  .avatar,
  .preview {
    height: 180px;
    width: 100%;
    object-fit: cover;
    &:hover{
      opacity:1
    }
  }
}
.add-icon-size {
  height: 20px;
}
.button-icon {
  background-color: none !important;
  border: 1px solid #aab1be;
  &:hover {
    background-color: transparent;
  }
}
:deep().min-height-textarea{
  height: 91px;
}
:deep().ql-toolbar{
  border: 1px solid #c6dcf5;
  border-radius: 5px !important;
  border-bottom-color: #ffffff ;
}
:deep().ql-container{
    box-shadow: inset 0 1px 3px rgba(1, 36, 84, 0.3) !important;
    border: 1px solid #c6dcf5 !important;
    border-top-color: transparent;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    .ql-editor{

      min-height: 117px;
      max-height: 117px;
      box-shadow: inset 0 1px 3px rgba(1, 36, 84, 0.3) !important;
      width: 100%;
      overflow-y: auto;
      background-color: #ffffff !important;
      font-size: 13px !important;
      color: #2b2b2d !important;
      font-family: Montserrat, sans-serif;
      border: none;
      strong{
        font-weight: 600;
      }
    }
    &.ql-disabled .ql-editor{
      background-color: #E8EEF2!important;
    }
}
.h-fit{
  height: fit-content;
}
.back-button{
  width: fit-content;
}
.info-url{
  font-size: 11px;
  font-weight: 600;
}

.mail-form{
  :deep() .textarea-header{
    height: 77px!important;
    width: 100%;
  }
  :deep() .textarea-block{
    height: 91px!important;
  }
  :deep() .textarea-footer{
    height: 117px!important;
  }
  .btn-light-blue{
    border-color: #0373a8;
    color: #0373a8;
    font-size: 14px;
    font-weight: 500;
    min-width: 166px;
    height: 33px;
    margin-top: 8px;
    margin-bottom: 26px;
    &:hover{
      color: white;
      background: #0373a8;
    }
  }
  .icon-card{
    cursor: pointer;
    width: auto;
  }

  .option-text{
    .max-input{
      width: 109px;
    }
    :deep().form-group{
      margin-bottom: 0px;
    }
  }
}
.custom-picker{
  display: block;
  position: absolute;

}
:deep().input-stevie.input-link{
  padding-left: 33px!important;
  background: url('/img/icons/stevie/link-horizontal.svg') no-repeat left;
  background-size: 15px;
  background-position: 8px 11px;
}
.custom-card{
  background-color: #ffffff;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  padding: 20px 21px;
  border-radius: 20px;
}

.segment{
  margin: 0 -21px;
  padding: 22px 31px 21px;
  &.footer{
    padding-top: 29px;
    margin-bottom: -20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.highlight-segment{
    background-color: #E7F6FD;
  }

}

.error-image-top {
  top: 12rem;
}

@media(max-width: 1350px) {
  .container-form-preview{
    flex-direction: column;
    .preview-mail{
      width: 100%;
      padding-left: 0;
      padding-top: 30px;
    }
  }
}

</style>
