import { TemplateClass, TemplateFormClass } from "./TemplateClass";

export class MailFormClass extends TemplateFormClass{
    constructor(mail){
        super(mail);
        this.subject = mail.subject ? mail.subject : null; 
    }
}
export class MailClass extends TemplateClass{
    constructor(mail){
        super(mail)
        this.subject = mail.subject ? mail.subject : null; 
    }
}